$body-background-color: $grey-dk-300;
$sidebar-color: $grey-dk-300;
$border-color: $grey-dk-200;
$body-text-color: $grey-lt-300;
$body-heading-color: $grey-lt-000;
$nav-child-link-color: #8d75c9;
$search-result-preview-color: $grey-dk-000;
$link-color: #8d75c9;
$btn-primary-color: #5f529c;
$base-button-color: $grey-dk-250;
$search-background-color: $grey-dk-250;
$table-background-color: $grey-dk-250;
$feedback-color: darken($sidebar-color, 3%);

// The following highlight theme is more legible than that used for the light color scheme

// @import "./vendor/OneDarkJekyll/syntax-one-dark";
// $code-background-color: #282c34; // OneDarkJekyll default for syntax-one-dark
// $code-linenumber-color: #abb2bf; // OneDarkJekyll .nf for syntax-one-dark

@import "./vendor/OneDarkJekyll/syntax-one-dark-vivid";

$code-background-color: #31343f; // OneDarkJekyll default for syntax-one-dark-vivid
$code-linenumber-color: #dee2f7; // OneDarkJekyll .nf for syntax-one-dark-vivid

// @import "./vendor/OneDarkJekyll/syntax-firewatch";
// $code-background-color: #282c34; // OneDarkJekyll default for syntax-firewatch
// $code-linenumber-color: #abb2bf; // OneDarkJekyll .nf for syntax-firewatch

// @import "./vendor/OneDarkJekyll/syntax-firewatch-green";
// $code-background-color: #282c34; // OneDarkJekyll default for syntax-firewatch-green
// $code-linenumber-color: #abb2bf; // OneDarkJekyll .nf for syntax-firewatch-green
